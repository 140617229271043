














































import { Component, Vue } from "vue-property-decorator";
import SwitchField from "../../commons/inputsAndControls/SwitchField.vue";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import {
  Laborer,
  UpdateLaborerInput,
  UpdateLaborerMutation,
  UpdateLaborerMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { apiClient, alerts } from "../../../utils";

const emptyFormData: UpdateLaborerInput = {
  ticketLimit: 0,
  isDriver: false,
  isControl: false,
  isCadCam: false,
};

@Component({
  components: {
    Button,
    Modal,
    TextField,
    SwitchField,
    ValidationObserver,
  },
})
export default class LaborerUpdateModal extends Vue {
  isOpen = false;
  isSending = false;

  laborerId = 0;
  laborerName = "";

  formData: UpdateLaborerInput = {
    ...emptyFormData,
  };

  created(): void {
    eventBus.$on(EventBus.LaborerUpdateModal, (payload: Laborer) => {
      this.formData = {
        ticketLimit: payload.ticketLimit,
        isDriver: payload.isDriver,
        isControl: payload.isControl,
        isCadCam: payload.isCadCam,
      };
      this.laborerName = `${payload.firstname} ${payload.surname}`;
      this.laborerId = payload.id;
      this.isOpen = true;
      this.initForm();
    });
  }

  async onSubmit(): Promise<void> {
    if (await (this.$refs.form as any).validate()) {
      this.sendForm(this.formData);
    }
  }

  async sendForm(formData: UpdateLaborerInput): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      UpdateLaborerMutation,
      UpdateLaborerMutationVariables
    >({
      ...api.laborers.updateLaborer,
      variables: {
        id: this.laborerId,
        input: formData,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("UPDATE_LABORER", "Uloženo");
      this.$emit("update", this.formData);
    }
    this.isOpen = false;
    this.isSending = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
