




































import { Component, Vue } from "vue-property-decorator";
import { api } from "../api";
import LaborerUpdateModal from "../components/app/laborers/LaborerUpdateModal.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import TabRouter from "../components/commons/TabRouter.vue";
import { EventBus, Routes } from "../enums";
import {
  Laborer,
  LaborerQuery,
  LaborerQueryVariables,
  LaborerStatusEnum,
  UpdateLaborerInput,
} from "../types/gqlGeneratedPrivate";
import { apiClient } from "../utils";
import { eventBus } from "../utils/eventBus";

@Component({
  components: {
    AppLayout,
    Button,
    LaborerUpdateModal,
    TabRouter,
  },
})
export default class LaborerDetailPage extends Vue {
  Routes = Routes;

  routes = [
    {
      title: "Zakázky",
      to: Routes.LaborerDetailTickets,
      error: "",
    },
    {
      title: "Události",
      to: Routes.LaborerDetailEvents,
      error: "",
    },
  ];

  laborer: Laborer | null = null;

  get laborerName() {
    return this.laborer
      ? `${this.laborer.firstname} ${this.laborer.surname}`
      : "";
  }

  get statusColors() {
    return {
      "color-green": this.laborerStatus === "Aktivní",
      "color-yellow": this.laborerStatus === "Neaktivní",
      "color-red": this.laborerStatus === "Smazaný",
    };
  }

  get laborerStatus() {
    const status = this.laborer ? this.laborer.status : "";
    if (status === LaborerStatusEnum.Active) {
      return "Aktivní";
    } else if (status === LaborerStatusEnum.Deleted) {
      return "Smazaný";
    } else {
      return "Neaktivní";
    }
  }

  get laborerLimit() {
    return this.laborer?.ticketLimit;
  }

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      LaborerQuery,
      LaborerQueryVariables
    >({
      ...api.laborers.laborer,
      variables: {
        id: parseInt(this.$route.params.id),
      },
    });

    if (data?.laborer) {
      this.laborer = data.laborer;
    }
  }

  update() {
    eventBus.$emit(EventBus.LaborerUpdateModal, this.laborer);
  }

  onUpdated(updatedData: UpdateLaborerInput) {
    this.laborer = Object.assign(this.laborer, updatedData);
  }
}
